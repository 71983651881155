.popup-boxdate {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
  }
   
  .boxdate {
    position: relative;
    width: 22%;
    margin: 0 auto;
    height: auto;
    max-height: 79vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: rgb(247, 245, 245);
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    margin-left: 247px;
    margin-top: 240px;
  }
   
  .close-icondate {
    content: 'x';
    cursor: pointer;
    position: fixed;
    /* right: calc(15% - 30px); */
    /* top: calc(100vh - 85vh - 33px); */
    background: #ededed;
    width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  margin-left: 156px;
    margin-top: -34px;
}


.dtspn{
 text-align: left !important;
 font-size: 14px ;
}


.datefiltcancel{
    background-color: white;
    color: #A47355;
    border: 1px solid;
    border-color: #A47355;
    width: 100px;
    height: 48px;
    border-radius: 5px;
    letter-spacing: 0.5px;
    margin-left: -10px;

  }
  .datefiltcancel:hover{
    cursor: pointer;
    background-color: #6B4C39;
      color: white;
    border: 1px solid;
    border-color: #A47355;
    width: 100px;
    height: 48px;
    border-radius: 5px;
    letter-spacing: 0.5px;
    margin-left: -10px;

  }
  .datefiltaply{
    background-color: #A47355;
    color: white !important;
    border: 1px solid;
    border-color: #ededed;
    width: 100px;
    border-radius: 5px;
    height: 48px;
    letter-spacing: 0.5px;
    margin-left: -5px;


  }
  .datefiltaply:hover{
    cursor: pointer;
    background-color: #6B4C39;
      color: white !important;
    border: 1px solid;
    border-color: #ededed;
    width: 100px;
    border-radius: 5px;
    height: 48px;
    letter-spacing: 0.5px;
    margin-left: -5px;

  }
  .fontsize14{
    font-size: 14px;
  }
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
    font-size: 14px;
}
.fontcolorlight{
    color: rgb(120, 118, 118);
}