/* Popup style */
.popup-box11 {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1111;
  }
  
  .box11 {
    position: relative;
    width: 25%;
    margin: 0 auto;
    height: 344px;
    max-height: 75vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 240px;
    margin-left: 971px; 
border-radius: 15px; }
  
  .close-icon11 {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  .chekmailspn{
      font-size: 21px !important;
      font-weight: bold;
  }
  .chekmailtxt{
    margin-top: 40px;
  }
  .chekurmail{
    margin-top: 40px;
  }
  .okbtn{
      border-width: 0px;
        left: 0px;
        top: 0px;
        margin-left: 128px;
        width: 160px;
        height: 52px;
        background: inherit;
        background-color: #A47355;
        border: none;
        border-radius: 5px;
        box-shadow: none;
        color: #FFFFFF;
        margin-top: 74px;
        text-align: center;
        font-size: 14px !important;
    
    }
  