body {
  font-family: 'Roboto' ;   
  font-size: 14px ;
  font-weight: 300 ;
  color: #404040;
   letter-spacing: 0.3px; 
}


code {
  font-family: 'Roboto';
      font-size: 14px ;
      color: #404040;

}

.data-table-extensions-filter{
  /* margin-left: 25;
  margin-left: 894px; */
  float: right !important;
 
}


.data-table-extensions{
  width: 107% !important;
   margin-left: -78px; 
}
.data-table-extensions > .data-table-extensions-filter{
  float: right !important;
  padding-top: 12px ;
}

.data-table-extensions > .data-table-extensions-action > button.download {
  display: none !important;
}
.iodownload{
margin-left: 0px;
margin-top: 7px;
color: #A47355;
}
.iodownload1{
  margin-left: 73px;
margin-top: 7px;
color: #A47355;
}


.data-table-extensions > .data-table-extensions-filter > .icon {
  /* float: left; */
  position: relative;
  display: block;
  top: 11px !important;
  margin-left: 2px;
  width: 20px;
  height: 24px;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAB80lEQVQ4T73Uv2tTURQH8O85972kxpqWNG2gOKhb21X8hXYQxCEKLp3cXNw6OIm0zTWWVjo4Cv4BIlIEF4c6FEQQi4uLZNTFYvNDiwWN9713jrxKQZOXBNLiXd89H77n3Hcv4YAXHbCH/wuOWh3kKJhSEDvjVbYtbffqKDFhrqRZ1ui+IrrB4PQfRCIieorAv1Vdoq1OcBsYY4jCV6Q0aAxuS9O8TGUQhRJeUGBZoTmQf65+lzaT0DYwvxA+1AiXBsSc+bxMjb+Lxq1mnITrpFKr3Utf7QnGMxNxdQNzvVr2niUVjFh3ioU2WMITW4uHPrbu+SdhYd6dFqK32vSy9RXaSZ6T0uiCawDmZq3srXYFx+aDs0p447N3eNPSj06Dz881a2Bvtl72nnQFh60O++LqBC5Wy/5aEpizvyaN8AcRmWwspitdwfjjWMk9VqUJn73zbSln1IxMuBcMztTK/nTPQ9kF72hBvGADoC/KOtuw/juANE5GggcMvkyq6xnjFz9ZavZMGG/Il3ScxD0C8RVAvqloRGzyAF6TaqBEFwWydoRT11rRrne5MPfzuLB/UgFPJXofz+yY1YEdcc/jpEloX49DK9qopIpYpSjuri8wLtxDAUwHnDr63dLXfYG7hzGjJjuFoT1s/2DCf9N3y51u0W9RndcVBJTNUgAAAABJRU5ErkJggg==); */
  background-image: url("https://www.flaticon.com/free-icons/search") !important;

  background-repeat: no-repeat;
  background-position: left center;
}

.data-table-extensions-action{
   margin-top: -93px; 
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border: 0;
  border-bottom: 1px solid black !important;
  outline: none;
  padding: 4px;
  margin-left: 21px !important;
  background-color: transparent;
  border-radius: 0 !important;
  
}
.data-table-extensions-action{
  margin-top: -34px !important;
}
.data-table-extensions > .data-table-extensions-action > button {
margin-right: -200px !important;

}
.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border: 0;
  border-bottom: 1px solid black !important;
  outline: none;
  padding: 4px;
  margin-left: 21px !important;
  background-color: transparent;
  border-radius: 0 !important;
  
}

.filter-text ::placeholder {
  content: 'Search' !important;
  color :red !important
}

.fSAFyE {
  position: relative;
  width: 112% !important;

  overflow-x: hidden !important;
  margin-left: 20px !important;
}

.bjfIGC {
  position: relative;
  display: table;
  width: 100%;
  height: auto !important;
}

.rdt_TableHeadRow{
    background-color: #f8f8f8!important;
    color: #404040 !important;
}

.rdt_Table{
  width: 110%;
}
.hkxIgn{
font-family: 'Open Sans semibold' !important;
font-size: 13px !important;
font-weight: 600 !important;
border-width: 1px !important;
    border-style: solid !important;
    border-color: rgba(250, 251, 252, 1) !important;

}
.ekpVOf {
  font-size: 13px;
  font-weight: 400;
  /* font-family: open sans !important; */
  color: #333333 !important;

}
.data-table-extensions > .data-table-extensions-action > button.print {
  background-image: none !important;
  background-position: center 4px;
  margin-left: 20px !important;
  display: none;

}


.ikGBKN{
margin-top: 5px;
}
.data-table-extensions{
  margin-top: -11px;
}
.sc-jrQzAO:nth-child(odd) {
  background-color: rgba(0,0,0,.03) !important;
}

.data-table-extensions > .data-table-extensions-action > .dropdown.drop {
  margin-right: -200px !important;
}
.data-table-extensions > .data-table-extensions-filter > .icon {

  margin-right: -15px !important;
}
.rdt_Pagination{
  position: relative;
}
/* .bhoXfZ{
  width: 41% !important;
    margin-top: -280px;
    margin-right: 112px;
    margin-left: -27px;
    border-style:none !important;
    background-color: none !important;
} */
.bWTWQu {
  /* margin: 0 4px;
  margin-top: -624px;
  margin-left: -680px;
  margin-right: 645px; */
  
  /* margin-top: -543px  !important; */
}
  

.fQmYxz {
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
  /* margin-top: -538px ; */
  /* margin-left: -237px; */
  margin-right: 630px !important;
     margin-left: -6px !important; 
}
.kBhHpT{
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
  /* margin-top: -538px ; */
  /* margin-left: -237px; */
  margin-right: 741px !important;
     margin-left: -36px !important; 
}
.gnaqej{
  margin-top: -624px;
  margin-left: -745px;
  margin-right: 753px; 
  
}
.data-table-extensions > .data-table-extensions-filter > .icon {

  content : url('../src/images/search2.png') !important; 
  color: gold !important; 

}
.eUeqdG {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
}
.bMgaAx  {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
.dlink{
  font-size: 14px;
  color: #404040;
  cursor: pointer !important;
  text-align: left !important;
}
.dlink:hover{
  font-size: 14px;
  color: #404040;
  text-decoration: none;
  cursor: pointer !important;
  
}

.rimwidthHeight {
  width: 2em;
  height: 28px;
  margin-top: 9px;
  margin-left: 28px;
  color: #9D7B36 !important;
}
.titlebar{
  margin-top: 12px;
  position: absolute;
  margin-left: 20px;
  color: #404040;
  font-weight: 500; 

  font-size: 16px !important;
}
.subtitlebartxt{
  font-size: 14px;
  letter-spacing: 0.3px;
}
.subtitlebar{
  text-align: left;
 margin-left: 30px;
}
.subtitlebarclpse{
  text-align: left;
 margin-left: 80px;
 letter-spacing: 0.3px;

}
.input1{
  margin-left: -37px;
}


.usergrid {
  left: 0px;
  top: 0px;
  width: 1160px;
  height: 596px;
  background: inherit;
  background-color: rgba(254, 253, 250, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  border-radius: 0px;
  box-shadow: none;
  margin-left: 36px;

}
.usergrid1 {
  width: 1300px;
  left: 0px;
  top: 0px;
  height: 596px;
  background: inherit;
  background-color: rgba(254, 253, 250, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  border-radius: 0px;
  box-shadow: none;
  margin-left: 78px;
}
.clientbtn{
  background-color :#f5f5f5;;
  justify-content: center;
height: 40px;
width: 101%;
margin-top: 45px;
margin-left: -1px !important;

}
.adclptag{
  top: 8px;
  right: 5px;
  position: relative;
    font-style: normal;
    font-size: 16px !important;
    color: #404040;
  font-weight: 500; 
}
.iodownload{
  margin-left: 0px;
  margin-top: 9px;
  color: #A47355;

}
.iodownload:hover{
  margin-left: 0px;
  margin-top: 9px;
  color: #A47355;
}
.main{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.filtericon{
color: #A47355;
margin-top: 12px;
}
.filtericon1{
  color: #A47355;
  margin-top: 10px;
  margin-left: -3px;
  }

.hide{
  display: none !important;
}
.patdetaildiv{
  border: 1px solid rgb(195, 194, 194);
border-radius: 8px;
height: 330px;
}
.patmedicl{
  display: block;
  text-align: left;
  padding-top: 20px;
  padding-left: 25px;
  border: 1px solid rgb(195, 194, 194);
margin-top: 30px;
height: 710px;
border-radius: 8px;
margin-bottom: 20px;
}
.quetionridiv{
  border: 1px solid rgb(195, 194, 194);
  border-radius: 8px;
  height: 590px;
  margin-bottom: 10px;
}
.margintop{
  margin-top: 30px;
}
.margintop1{
  margin-top: 10px;
}
.margintop4{
  margin-top: 5px !important;
}
.margintop2{
  margin-top: 10px;
}
.margintop3{
  margin-top: 19px;
}
.margintop5{
  margin-top: 13px;

}
.margintop6{
  margin-top: 1px;

}

.linkbtn{
  margin-bottom: 10px;
  text-align: left;
}
.questionary{
border: none !important;
background-color: transparent !important;
font-size: 20px;
color: #404040;
font-weight: 500;
cursor: pointer;
border-bottom: 5px solid #A47355 !important;
}
.questspn{
  font-size: 20px;
  color: #404040;
  font-weight: 500;
}
.center{
  text-align: center !important;
}
.conclusion{
  border: none !important;
  background-color: transparent !important;
  font-size: 20px;
  margin-left: 10px;
  color: #908f8f;
  cursor: pointer;
  text-decoration: none !important;

}
.conclusion:hover{
  text-decoration: none !important;
cursor: pointer;
}
a{
  text-decoration: none !important;

}
.patdetaildiv{
  display: block;
  text-align: left;
  padding-top: 20px;
  padding-left: 25px;
}
.patnme{
font-size: 20px;
font-weight: 600;
margin-top: 20px;
}
.patno{
margin-top: 13px;
margin-bottom: 3px;
}
.pateml{

}
.hcspn{
text-align: left;
font-weight: bold;
}
.pcospn{
  text-align: left;
  margin-left: -18px;
}
.textright{
  text-align: right;
}
.textleft{
  text-align: left;
  line-height: 1.6;

}
.fontsize16{
  font-size: 16px;
}
.conclutext{
  text-align: left;
  line-height: 1.7;
}
.center{
  padding: 20px;
  border: 1px solid rgb(195, 194, 194);
  border-radius: 8px;
  height: 414px;
  margin-bottom: 20px;
margin-top: 20px;
}
.center1{
  text-align: center;
}
.backbtn{
  color: #A47355;
    border: 1px solid #A47355;
    background-color: transparent;
    width: 153px;
    height: 51px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;

}
.backbtn:hover{
  color: #ffffff;
    border: 1px solid #A47355;
    background-color: #A47355;
    width: 153px;
    height: 51px;
    border-radius: 3px;
    margin-top: 5px;
    margin-bottom: 5px;

}
.pcpstxt{
  font-size: 34px;
  font-weight: bold;
}
.pcosspn{
  margin-top: 35px;
}
.dt{
  font-size: 16px;
  font-weight: 500;
}
.margleft{
  margin-left: 43px !important;
}
.margleft2{
  margin-left: 15px !important;
}
.margleft1{
  margin-left: 79px !important;
}
.margleft3{
  margin-left: 100px !important;
}
.margleft4{
  margin-left: 5px;
}
.fontsize12{
  font-size: 12px;
}
.pcosbox{
  border: 2px solid lightgray;
    border-radius: 14px;
    padding-top: 50px;
    padding-bottom: 18px;
    width: 102% !important;
}
.num{
  font-size: 34px;
  font-weight: 500;
}
.txt{
  font-size: 16px;
  font-weight: 500;
}
.totltxt{
  margin-top: 5px;
}
.input[type="radio"]{
  width: 20px !important;
  height: 18px !important
}
.dispinline{
  display: inline !important;
}
.linheight{
  /* line-height: 1.7; */
  margin-top: 7px;
}
.addicon{
  margin-top: 8px;
  position: absolute;
}
::-webkit-input-placeholder {
  font-size: 14px!important;
}

:-moz-placeholder { /* Firefox 18- */
     font-size: 14px!important;
}
::-moz-placeholder {  /* Firefox 19+ */
     font-size: 14px!important;
}
.datepick{
  text-align: left !important;
}
.input{
  border: 1px solid rgb(202, 198, 198) !important;
  height: 52px !important;
  border-radius: 5px;
}
.cancelbtn{
  background-color: white;
  color: #A47355;
  border: 1px solid;
  border-color: #A47355;
  width: 139px;
  height: 50px;
  border-radius: 5px;
  letter-spacing: 0.5px;
}
.cancelbtn:hover{
  cursor: pointer;
  background-color: #6B4C39;
    color: white;
  border: 1px solid;
  border-color: #A47355;
  width: 139px;
  height: 50px;
  border-radius: 5px;
  letter-spacing: 0.5px;

}
.savebtn{
  background-color: #A47355;
  color: white !important;
  border: 1px solid;
  border-color: #ededed;
  width: 139px;
  border-radius: 5px;
  height: 50px;
  letter-spacing: 0.5px;

}
.savebtn:hover{
  cursor: pointer;
  background-color: #6B4C39;
    color: white !important;
  border: 1px solid;
  border-color: #ededed;
  width: 139px;
  border-radius: 5px;
  height: 50px;
  letter-spacing: 0.5px;

}
.Toastify__toast--warning {
  background: #edc627 !important;
  color: white !important;
}

.Toastify__toast--success {
  background: #07bc0c !important;
  color: white !important;
  
}
.Toastify__toast--success::before {
content : url('../src/images/checkmark.png');  
background: #07bc0c !important;
color: white !important;
font-size: 19px;
font-weight: 800;
padding-top: 14px !important;
margin-left: 10px !important; 
  
}

.Toastify__toast--warn::before {
  background: rgb(238, 176, 60) !important;
  color: white !important;
  font-size: 19px;
  font-weight: 800;
  padding-top: 14px !important;
  margin-left: 10px !important; 
    
  }

.Toastify__toast-container {
  width: 300px !important;
}
.Toastify__toast-container  .Toastify__toast-body {
  text-align: center !important;
  margin-right: 10px !important;
}
.Toastify__toast--error {
  background: #e74c3c !important;
  color: white !important;
}
.Toastify__toast--error::before {
  content : url('../src/images/checkmark.png');  

  background: #e74c3c !important;
  color: white !important;
  font-size: 19px;
font-weight: 800;
padding-top: 14px !important;
margin-left: 10px !important;
}
.Toastify__close-button{
  color: white !important;
}
.editicon{
  margin-top: 8px;
  margin-left: 17px;
}
.displyflex{
  display: flex;
}
.colorfent{
  color: #585858;
}

/* Popup style */
.popup-box12{
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}

.box12 {
  position: relative;
  width: 26%;
  margin: 0 auto;
  height: 213px;
  max-height: 75vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  margin-top: 207px;
  margin-left: 716px; 
}

.close-icon12 {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
.Deletpopdiv{
background-color:#FF453A;
margin-left: -20px;
  margin-right: -20px;
  padding: 10px;
  margin-top: -20px;
}
.warnspn{
  font-size: 21px !important;
  font-weight: bold;
  color: white;
}
.warntxtdiv{
margin-top: 17px;
}
.warntxt{
font-size: 18px !important;
color: #999;
}
.warnbtn{
    margin-top: 25px;
}
.cnceldeletpopbtn{
  width: 133px;
  border-radius: 4px;
  padding-top: 0px;
  height: 35px;
  margin-right: 40px;
  background-color: white;
  color: #A47355;
  border: 1px solid #A47355;
  letter-spacing: 0.5px;
}
.cnceldeletpopbtn:hover{
  width: 133px;
  border-radius: 4px;
  padding-top: 0px;
  height: 35px;
  margin-right: 40px;
  background-color: #A47355;
  color: white;
  border: 1px solid rgb(202, 198, 198);
  letter-spacing: 0.5px;

}
.addeletepopbtn{
  width: 133px;
  border-radius: 4px;
  background-color: #A47355;
  border: 1px solid #A47355;
  height: 35px;
  color: white !important;
  letter-spacing: 0.5px;

}
.addeletepopbtn:hover{
  width: 133px;
  border-radius: 4px;
  border: 1px solid #6B4C39;
  background-color: #6B4C39;
  height: 35px;
  color: white !important;
  letter-spacing: 0.5px;

}

.couponlink{
  color: #A47355;
}
.couponlink:hover{
  color: #A47355 !important;
}
.patlinkbtn{
  width: 146px;
  border-radius: 4px;
  background-color: #A47355;
  border: 1px solid #A47355;
  height: 48px;
  color: white !important;
}
.patlinkbtn:hover{
  width: 146px;
  border-radius: 4px;
  background-color: #6B4C39;
  border: 1px solid #6B4C39;
  height: 48px;
  color: white !important;
}
.hclinkbtn{
border: none !important;
background-color: transparent;
width: 146px;
height: 48px;
font-weight: bold;
color: #585858;

}
.hclinkbtn:hover{
  width: 146px;
  border-radius: 4px;
  background-color: #6B4C39;
  border: 1px solid #6B4C39;
  height: 48px;
  color: white !important;
  font-weight: 500;

}
.camplinkbtn{
  border: none !important;
  background-color: transparent;
  width: 146px;
  height: 48px;
  font-weight: bold;
  color: #585858;
}
.camplinkbtn:hover{
  width: 146px;
  border-radius: 4px;
  background-color: #6B4C39;
  border: 1px solid #6B4C39;
  height: 48px;
  color: white !important;
  font-weight: 500;

}
.custfiltdiv{
  border: 1px solid lightgray;
  margin-bottom: -5px;
  border-radius: 4px;
}
.paddingleft{
  margin-left: -104px !important;

}
.reportbtnlink{
  margin-right: 0px !important;
}
.filterdiv{
  text-align: right;
}
.nowraptxt{
  white-space: nowrap;
  margin-top: 8px;

}
.dashmain{
  margin-left: 24px;
}
.dashmainclpse{
  margin-left: 90px;
  width: 80% ;
}
.upperdiv{
  height: auto;
  


}
.upperdivclpse{
  height: auto;
  

}
.lowerrdiv{
  padding-top: 5px;
padding-bottom: 5px;
  height: auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  border: 1px solid lightgray;
  margin-left: -16px !important;
  margin-right: -16px !important;
  background-color: #34C759;
  margin-bottom: -1px !important;
}
.lowerrdivclpse{
height: auto;
border: 1px solid lightgray;
  margin-left: -1px !important;
  margin-right: -1px !important;
  background-color: #34C759;
  margin-bottom: -1px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.lowerrdivred{
  padding-top: 5px;
padding-bottom: 5px;
  height: auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  border: 1px solid lightgray;
  margin-left: -16px !important;
  margin-right: -16px !important;
  background-color: #FF3B30;
  margin-bottom: -1px !important;
}
.lowerrdivred1{
  padding-top: 7px;
padding-bottom: 7px;
  height: auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  border: 1px solid lightgray;
  margin-left: -16px !important;
  margin-right: -16px !important;
  background-color: #FF3B30;
  margin-bottom: -1px !important;
}
.lowerrdivclpsered{
height: auto;
border: 1px solid lightgray;
  margin-left: -1px !important;
  margin-right: -1px !important;
  background-color: #FF3B30;
  margin-bottom: -1px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.maindivdash{
  border: 1px solid rgb(205, 204, 204);
  /* box-shadow: 0px 3px 4px rgb(134, 134, 134); */
  box-shadow: 0 5px 2px -2px rgb(170, 169, 169);

border-radius: 15px;
width: 267px;
background-color: rgb(221, 253, 252);

}
.maindivdashclpse{
  border: 1px solid rgb(205, 204, 204);
  box-shadow: 0 5px 2px -2px rgb(170, 169, 169);
border-radius: 15px;
width: 300px;
background-color: rgb(221, 253, 252);

}

.maindivdashred{
  border: 1px solid rgb(205, 204, 204);
  box-shadow: 0 5px 2px -2px rgb(170, 169, 169);
border-radius: 15px;
width: 267px;
background-color:#f7c9c7;
}
.maindivdashclpsered{
  border: 1px solid rgb(205, 204, 204);
  box-shadow: 0 5px 2px -2px rgb(170, 169, 169);
border-radius: 15px;
width: 300px;
background-color:#f7c9c7;

}
.marglft10{
  margin-left: 5px !important;
}


.zerodiv{
  /* margin-left: 9px; */
  white-space: nowrap;
}
.middlediv{
  padding-bottom: 17px;
}
.zero{
  margin-top: 35px;
  font-weight: bold;
  font-size: 35px;
  margin-top: -11px !important;
}
.zero1{
  margin-top: 35px;
  font-weight: bold;
  font-size: 18px;
  margin-top: -11px !important;
}
.zero2{
  margin-top: 35px;
  font-weight: bold;
  font-size: 18px;
  margin-top: -11px !important;
}
.hcnmespn{
  margin-top: 5px;
  font-weight: bold;
  font-size: 16px;
}
.totaltblespn{
  font-size: 20px;
  margin-top: -23px;
  color: #ffffff;
}
.totaltblespn1{
  font-size: 16px;
  margin-top: -23px;
  color: #ffffff;

}
.usericon{
  margin-top: 15px;
  margin-top: 15px;
    width: 72px;
    height: 23px
}
.tblfont{
  font-size: 16px;
  font-weight: bold;
}
.todayspan{
  color: #ffffff;
    background-color: #9D7B36;
    width: 90px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 3px;
    padding-bottom: 1px;
    font-size: 14px !important;
}
.dinerimg{
  margin-top: 5px;
  width: 39px;
}
.marglft{
  margin-left: 11px !important;
}
.justleftdash{
  padding-left: 0px !important;
}
.icondashbrd{
  margin-top: -3px;
}
.boxcolored{
  background-color: lightgray;
}
.maingrapdiv{
  border: 1px solid rgb(230, 229, 229);
  border-radius: 15px;
  height: auto;
  margin-left: 12px;
  background-color: rgb(234, 231, 231);
}
.maingrapdiv1{
  border: 1px solid rgb(230, 229, 229);
  border-radius: 15px;
  height: auto;
  margin-left: 35px;
  max-width: 45.666667% !important;

  background-color: #ede2d8;
}
.chartsdiv{
  padding-right: 20px;
  padding-top: 35px;
  padding-left: 20px;
  padding-bottom: 35px;
}
.healthgrptopdiv{
background-color: rgb(186, 172, 172);
border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -16px;
  margin-right: -16px;

}
.healthgrptopdiv1{
  background-color: rgb(186, 172, 172);
  border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: -16px;
    margin-right: -16px;
    background-color: #d4a283;
width: 466px;
  }
  .mainongoingdiv{
    width: 513px;
  }
.healthgrpspn{
font-size: 20px;
font-weight: 600;
}
.healthgrpspn1{
  font-size: 18px;
  font-weight: 600;
  }
.colorwhitback{
  background-color: #ffffff;
  font-size: 16px !important;
  color: black;
}
.headerspn{
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

}
.cardrow{
 flex-wrap:nowrap !important

}
.ongpingdatarow{
  padding-left: 22px !important;
}
.usermntmgtfooterexp{
  margin-top: 20px;
  padding: 0px;
  padding-top: 5px;
  height: 30px;
  /* background-color: #411962; */
  background-color: #534846;
  color: white;
  position: fixed;
  bottom: 0;
  /* left: 0; */
  width: 87%;
  margin-left: 1px;
}
.usermngmntfootercpls{
  margin-top: 20px;
  padding: 0px;
  padding-top: 5px;
  height: 30px;
  /* background-color: #411962; */
  background-color: #534846;
  color: white;
  position: fixed;
  bottom: 0;
  /* left: 0; */
  width: 97%;
  margin-left: 46px;
}
.adreservdate{
  padding: 14px;
  width: 363px !important;
  border: 1px solid rgb(202, 198, 198);
  border-radius: 5px;
}
.width340{
  width: 268px !important;
}
button, input {
  overflow: visible;
  color: rgb(90, 88, 88) ;
}
.flexwrap{
  flex-wrap: wrap !important;
}
.copylinkbtn{
  background-color: transparent !important;
  border: none !important;
}
.copylinkicon{
  margin-top: -17px;
  position: absolute;
}
.filterdropdate{
  padding: 14px;
  width: 129px !important;
  border: 1px solid rgb(202, 198, 198);
  border-radius: 5px;
}
/* for togglrbtn */
.btnyesno {
  width: 60PX;
  border-radius: 4px;
  height: 40px;
  background-color: white;
  color: black;
  border: 1px solid rgb(202, 198, 198);
  margin-top: 13px;
}
/*for toggle button */
.checkbox {
/* transform: scale(1.5); */
width: 5.9rem;
height: 1.7rem;
border-radius: 20px; 
border: 1px solid rgb(228, 227, 226);
background: #b3b2b2;
color: black;
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: space-between;
cursor: pointer;
margin-top: 10px;
text-align: center !important;
/* box-shadow: 0px 0.5px 1px 0px rgba(50, 50, 50, 0.72); */
}

#custchek{
margin-top: 1px;
}
.checkbox--on {
flex-direction: row;
background: #A47355;
border: 1px solid rgb(250, 249, 249);
color: black;
}

.checkbox--on .checkbox__ball {
/* box-shadow: 0px 3px 4px 0px rgb(29, 80, 29); */

}

.checkbox__ball {
background: whitesmoke;
/* box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72); */
width: 20px;
height: 81%;
border-radius: 50%;
}

.checkbox__text {
font-size: 16px;
 margin-left: 7px; 
 margin-right: 10px; 
}




.switch {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 30px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(192, 192, 192);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #A47355;
}
input:focus + .slider {
  box-shadow: 0 0 1px #A47355;
}
input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.margbottom{
  margin-bottom: 40px !important;
}
/* for coupon */
.coupondiv{
  border: 1px solid gray;
  border-radius: 18px;
  background-color: #6B4C39;
  /* background-image: radial-gradient(#a5785d, #8d664d, #6B4C39); */
  background-image: radial-gradient(#a5785d 5%, #533a2c 230px, #533a2c 60%);

  padding-left: 15px;
  width: 500px;
  padding-bottom: 40px;
  padding-top: 34px;
}
.couponlogo{
  width: 93px;
  height: 68px;
  margin-top: 9px;
}
.couponspn{
  color: #ededed;
  margin-top: 15px;
  font-weight: 500 !important;
  font-size: 16px;
}
.couponspn2{
  font-size: 24px;
  color: #F3C666;
  font-weight: bold;
}
.couponspn3{
  font-size: 20px;
  color: #ededed;
  font-weight: bold;
letter-spacing: 0.6px;
}
.couponspn33{
  font-size: 16px;
  color: #ededed;
  font-weight: bold;
letter-spacing: 0.6px;
}
.coponcodediv{
  border: 2px solid #d9d8d8;
  border-radius: 7px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 27px !important;
  padding-right: 27px !important;
}
.validtilspn{
  font-size: 12px;
  color: #ededed;
}
.paddinglft{
  padding-left: 10px !important;
}
.paddinglft1{
  padding-left: 27px !important;

}
.couponspndiv{
  white-space:nowrap !important 
}
.disbleclass{
  cursor: not-allowed;
  background-color: rgb(241, 240, 240) !important;
}
.texttransformcapital{
  text-transform: uppercase !important;
  }
  .texttransformcapitalize{
    text-transform: capitalize !important;
    }