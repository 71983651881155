body{
  font-family: 'Roboto' ;   
    font-size: 14px ;
  }
  .loginrow{
      margin-right: 0px !important;
    }
    .Logining{
       text-align: left !important; 
    }
    .main-div1{
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 20px;
        width: 70%;
        margin: 80px;
        height: 490px;
        margin-top: 80px !important;
        margin-left: 132px;
    }
    .welcome1{
      color: #404040;
      font-weight: 600;
      font-size: 20px;
      margin-top: 15px;    
      margin-top: 15px;
      letter-spacing: 0.5px;
    }
    .forgotpasspn{
        font-size: 16px;
        color: #696969;
       letter-spacing: 0.5px;
    }
    .spntxt{
        margin-top: 40px;
        margin-bottom: 50px;
    }
    .usernme1{
      margin-top: -5px;
    }
    .show1{
      margin-left: 390px !important;
      margin-top: -3px !important;
    
    
    }
    .inputWithIcon1 {
      position: relative;
    }
    
    .inputWithIcon i {
      position: absolute;
      left: 0;
      top: 8px;
      padding: 9px 8px;
      transition: 0.3s;
       /* padding-left: 30px;  */
       margin-left: 0px;
      margin-bottom: -6px;
      justify-content: center ;
    }
    .inputWithIcon.inputIconBg i {
      background-color: #aaa;
      color: #fff;
      padding: 9px 4px;
      border-radius: 4px 0 0 4px;
    }
    .inputWithIcon input[type="password"] ,input[type="email"]{
      padding-left: 10px;
    }
    .ulable1{
      /* color: #C4C4C4; */
      color: rgb(153, 149, 149);
    
      text-align: left;
        margin-left: 42px;
        margin-top: 18px;
        font-size: 14px !important;
     
    }
    .sgnin1{
      font-family: 'Open Sans Semibold', 'Open Sans Regular', 'Open Sans';
        font-weight: 600;
        font-style: normal;
        font-size: 14px !important;
        text-align: left;
        margin-top: -40px;
        color: #333333;
      }
      .redp{
        color: red;
        /* margin-top: -15px; */
        text-align: left;
        /* margin-left: 44px; */
      }
      .place{
        width:396px;
      height: 47px;
        border: 1px solid rgb(230, 227, 227);
        border-radius: 3px;
         margin-top: 2px; 
      }
    .forgot{
      left: 0px;
        width: 114px;
        font-style: normal;
        /* color: rgb(153, 149, 149); */
           color: #C4C4C4; 
    text-decoration: none !important;
        margin-left: 27px;
        padding-left: 5px;
        font-size: 14px !important;
    }
    .welcometext{
      font-size: 24px;
      margin-top: 48px;
      color: #535353;
      font-weight: 600;
    }
    loginlogo{
      margin-top: 20px;
    }
    .remember{
      /* margin-left: 39px; */
      margin-left: 41px;
      /* color: rgb(153, 149, 149); */
      color: #C4C4C4; 
      font-size: 14px !important;
    
    }
    .chek{
      /* color: rgb(153, 149, 149); */
      font-size: 14px;
      color: #C4C4C4; 
    
    
    
    
    
    }
    .sub1{
      border-width: 0px;
        left: 0px;
        top: 0px;
        margin-left: 128px;
        width: 153px;
        height: 52px;
        background: inherit;
        background-color: #A47355;
        border: none;
        border-radius: 5px;
        box-shadow: none;
        color: #FFFFFF;
        margin-top: 74px;
        font-weight: bold;
        text-align: center;
        font-size: 14px !important;
        letter-spacing: 0.5px;
    
    }
    .sub1:hover{
      cursor: pointer;
      background-color: #6B4C39;
    }
    .sign1{
      margin-left: -124px;
    }
    .emaillbl{
      color: #404040 !important;
    }
    .xyztxt{
      color: #404040 !important;
      font-size: 14px !important;
    }
    .logimg{
      margin-top: 118px;
      margin-left:111px;
    }
    .redpforgot{
      color: red;
    }
    .lablered{
      color: red;
    }