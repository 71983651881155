.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
  }
   
  .box {
    position: relative;
    width: 21%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    margin-left: 247px;
    margin-top: 101px;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    /* right: calc(15% - 30px); */
    /* top: calc(100vh - 85vh - 33px); */
    background: #ededed;
    width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  margin-left: 156px;
    margin-top: -34px;
}
.txtLeft{
  text-align: left;
}
.filterbtns{
  display: flex;
}
.filterbydiv{
  display: flex;
  border: 1px solid;
    border-color: #e0e0e0;
    margin-bottom: -6px;
    padding: 8px;
    /* width: 293px; */
    margin-right: -18px;
    margin-left: -19px;
    margin-top: -17px;
}

.filterby{
  margin-right: 0px;
  margin-left: 8px;
  
 

}
.clearfilter{
  margin-left: 176px;
  color: rgb(122, 121, 121);
}
.filtcancel{
  margin-left: -2px;
    background-color: white;
    color: #A47355;
    border: 1px solid;
    border-color: #A47355;
    width: 137px;
    height: 48px;
    border-radius: 7px;
    padding-left: 0px !important;
    letter-spacing: 0.5px;
}

.filtaply{
  margin-left: -7px;
  background-color: #A47355;
  color: white !important;
  border: 1px solid;
  border-color: #ededed;
  width: 137px;
  border-radius: 7px;
  height: 48px;
  margin-bottom: -10px;
  padding-right: 0px !important;
  letter-spacing: 0.5px;

}


.filtaplyenbl{
  margin-left: 23px;
  color: white;
  background-color: #411962 !important;
border: 1px solid;
border-color: #ededed;
width: 94px;
height: 36px;
margin-bottom: -10px;
}
.tablecapmenu{
  margin-top: -9px;
}
.filterchek{
  margin-top: 1px;
  margin-left: 37px;
}
.availablemenudiv{
margin-top: -98px;
}
.timemenudiv{
  margin-top: -65px;
}
.secpopmenu{
  /* display: block; */
  margin-top: 5px;
  margin-left: 6px !important;
}
.secspan{
  margin-left: 10px;
}
.tablecapmenudiv{
  margin-top: -39px;
}
.secspantbl{
  margin-left: 10px;

}
.secspantime{
  margin-left: 10px;
}
.secspanavailble{
  margin-left: 10px;
}
.availblactive{
    background-color: #A47355;
    color: white;
    padding-left: 17px;
    padding-top: 3px;
    padding-bottom: 4px;
    width: 160px;
    cursor: pointer;
}
.all{
    margin-bottom: 8px;
    cursor: pointer;
}
.inactiv{
  cursor: pointer;
}
