
body {
    margin: 0;
    font-family: 'Roboto' ;   
    font-size: 14px ;
    color: #404040; 
  font-weight: 300;
  }
  .Sidebar{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 214px;
    height: 100%;
    background-color: #534846;
    /* color: #ffffff !important; */
    color: #ffffff;   
     font-size: 16px !important;
    font-weight: bold !important;
  
  }
  .sidebar-items{
    /* display: flex; */
    flex-direction: column;
    padding: 0.8px;
    margin-top: 10px;
  }
  .sidebar-items .item{
    /* display: flex; */
    align-items: center;
     /* padding: 15px 0px; */
    padding-bottom: 12px !important;
    padding-top: 8px !important;
    transition: background-color 0.2s;
    cursor: pointer;
    margin-top: -1px;
    padding-left: 40px;
    text-decoration: none !important;
    border: none !important;
  }
  .marleftside{
    margin-left: -83px !important;
  }
  .marleftside1{
    margin-left: -97px !important;
  }
  .Reservdrop{
    height: 45px;
  }
  .sidebar-header{
    display: flex;
    align-items: center;
     padding: 6px; 
     margin-top: 5px;
  
  }
  .flex{
    display: grid;
  }
  .tatcclpse{
    font-size: 18px;
    margin-left: -12px;
  }
  .borderbottomnon{
    border-bottom: none !important;
  }
  li{
      list-style: none;
  
  }
  .sidebar-icon{
    
    /* width: 2.65em;
    height: 1.55em; */
    /* width: 4.69em;
    height: 1.45em; */
    cursor: pointer;
    margin-right: 15px;
    user-select: none;
    margin-bottom: -6px;
    
  
  }
  .sidebar-icon2{
    
    /* width: 1.65em;
    height: 1.78em; */
    width: 54px;
    height: 41px;
    cursor: pointer;
    margin-left: -12px;
    margin-right: -4px;
    margin-top: 5px;    
    user-select: none;
    color:white !important ;
  
  }
  .sidebar-logo{
    width: 110px;
    height: 81px;
  margin-left: 39px;
  }
  .sidebar-text{
    margin-top: -3px;
    font-weight: 500;
  }
  .tatc{
margin-top: 5px;
font-size: 34px;
margin-left: 35px;
  }

  
    
  
  .Sidebar.collapsed .Sidebar-icon{
    margin-right: 0;
     margin-left: -1px; 
  }
  .Sidebar.collapsed .Sidebar-icon2{
    margin-left: 1px;
  }
  .Sidebar.collapsed .sidebar-logout{
    margin-left: 9px;
    margin-top: 10px;
  }
  .Sidebar.collapsed .sidebar-items{
    padding-left: 0px !important;
  }
  
  
  .Sidebar.collapsed{
    width: 46px;
  }
  .Sidebar.collapsed .sidebar-text,
  .Sidebar.collapsed .sidebar-logo,
  .Sidebar.collapsed .sidebar-logouttext,
  .Sidebar.collapsed .logout{
    
    display: none !important;
  }
  .sidebar-icon1{
    width: 86%;
  height: 3.29em;
  cursor: pointer;
  position: absolute;
  margin-left: 1px;
  margin-top: 1px;  
  color: black !important; 
  text-align: left;
  /* background-color: #ebe9e9; */
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
  box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
  
  }
  .sidebar-text{
    text-decoration: none !important;
  }
  
  .remanuLeftMargin{
  width: 97%;
  height: 2.95em;
  cursor: pointer;
  position: absolute;
  margin-left: 45px;
  margin-top: 1px;
  color: black !important;
  text-align: left;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
  box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
  }
  .sidebar-iconviw{
  width: 2.25em;
  height: 1.95em;
  cursor: pointer;
  position: absolute;
  margin-left: -510px;
  margin-top: 11px;  
  color: black !important; 
  
  }
  .sidebar-iconadd{
  width: 2.25em;
  height: 1.95em;
  cursor: pointer;
  position: absolute;
  margin-left: -520px;
  margin-top: 11px;  
  color: black !important; 
  
  }
  
  
  .Sidebar.collapsed .Sidebar-icon1{
    margin-right: 0;
    margin-top: 0;
  }
  .Sidebar.collapsed .icondash{
    margin-right: 0;
    margin-left:-46px!important;
  }
  .Sidebar.collapsed .icondash1{
    margin-right: 0;
    margin-left:-48px!important;
  }
  
  .Sidebar.collapsed .marleftside{
    margin-right: 0;
    margin-left:-47px !important;
  }
  .Sidebar.collapsed .marleftside1{
    margin-right: 0;
    margin-left:-47px !important;
  }
  .Sidebar.collapsed .iconmenu{
    margin-right: 0;
    margin-left:-11px !important;
  }
  .Sidebar.collapsed .iconinvoices{
    margin-right: 0;
    margin-left:-9px !important;
  }
  
  .Sidebar.collapsed .icontime{
    margin-right: 0;
    margin-left:-10px !important;
  }
  
  .Sidebar.collapsed .icondashlogout{
    margin-right: 0;
    margin-left:-45px!important;
  }
  
   .Sidebar.collapsed .container{
    width: 120% !important;
    max-width: 120%;
  } 
  
  .clinttxt{
  margin-right: 28px;
  }
  .sidenav---navitem---9uL5T{
  display: inline-flex;
  margin-left: -45px;
  margin-top: 12px;
  }
  
  
  
  .sidenav---navtext---1AE_f{
  justify-content: right;
  }
  .icondash{
  margin-left: -72px !important;
  } 
  .icondash1{
    margin-left: -69px !important;
    } 
  .iconmenu{
    margin-left: -20px !important;
  
  }
  .icondashreserv{
  margin-left: -101px;
  }
  .icontime{
    margin-left: -48px;
  }
  .iconuser{
    margin-left: 0px;
  }
  .iconinvoices{
    margin-left: -95px;
  }
  .icondashsetting{
  margin-left: -65px;
  }
  .icondashlogout{
  margin-left: -18px!important;
  }
  .sidebar-logout{
  width: 25px;
    height: 25px;
    margin-left: 4px;
  }
  .sidebar-logouttext{
  left: 2px;
    top: 8px;
    width: 217px;
    word-wrap: break-word;
  }
  /* .logout{
  left: 0px;
    top: 0px;
    width: 20px;
    height: 16px;
    color:#9D7B36 !important ;
  
  }
  .logoutdiv{
  margin-top: 195px;
  width: 230px;
  } */
  .logedout{
  /* margin-top: 150px !important; */
  }
  .active{
  background-color: #A47355;
  left: 0px;
  top: 10px;
  /* width: 373px; */
  border: none;
  text-align: center !important;
  justify-content: center;
  align-items: center; 
  color: white !important;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  border-radius: 0px;
  padding-top: 2px;
  text-decoration: none !important;
  }
  
  .dropdown-item:focus, .dropdown-item:hover {
    color: white !important;
    text-decoration: none !important;
    background-color: #2b044b !important;
  }
  
  .dropdown-menu {
  position: absolute;
  top: 113% !important;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: white !important;
  text-align: left;
  list-style: none;
  background-color: #411962 !important ;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0;
  transform: none !important;
  width: 100%;
  }
  
  .btn-primary{
    background-color: #411962 !important;
    border-color: #411962 !important;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding-left: 20px !important;
    clear: both;
    font-weight: 400;
    color: white !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
  }
  
  .ddlsidebar{
  display: inline-block !important;
  margin-left: -12px !important;
  }
  #dropdown-basic-button{
  margin-top: -3px;
  }
  .expandreserv{
  /* height: 100px; */
  margin-bottom: 100px;
  }
  .sidemenu{
    left: 0px;
      top: 10px;
      width: 373px;
      height: 35px;
      border: none;
      text-align: center !important;
      justify-content: center;
      align-items: center; 
      color: rgb(222, 220, 220) ;
      font-weight: 400;
      font-style: normal;
      text-decoration: none !important;
      border-radius: 0px;
      padding-top: 2px;
      margin-bottom: -27px;
  }
  .sidemenu:hover{
    color: rgb(239, 238, 238);
    text-decoration: none;
  }
  .margintoplog{
    margin-top: 225px !important;
  }
  .logoutflex{
    display: flex !important;
    height: 70px;
    padding-bottom: 49px !important;
    margin-top: -52px ;
  }
  .usernmerol{
    margin-left: -18px !important;
    text-align: left;
    word-wrap: break-word !important;
  }