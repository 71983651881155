body{
  font-family: 'Roboto' ;   
    font-size: 14px ;
  }
  .loginrow1{
      /* margin-right: 0px !important; */
      /* border: 1px solid #A47355; */
      border: 1px solid #f1c9af;
  /* border-radius: 15px; */
    margin-right: 91px !important;
    margin-left: 91px !important;
    margin-top: 30px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-radius: 20px;
    }
    .Logining1{
       text-align: left !important; 
    }
    .logimgborder{
       /* border-right: 1px solid #6B4C39; */

       height: 650px !important;
       padding-bottom: 1px;

    }
    .main-div{
        height: auto;
        border: 1px solid #c4c4c4;
        background-color: #771E19;
        border-radius: 8px;
        padding: 20px;
        width: 68%;
        /* margin: 165px; */
        height: autopx;
        margin-top: 60px !important;
        margin-left: 102px;
        padding-bottom: 30px;
    }
    .welcome{
      color: #FFFFFF;
      font-weight: 600;
      font-size: 20px;
      margin-top: 15px;    
      margin-top: 15px;
      letter-spacing: 0.5px;
    }
    .usernme{
      margin-top: -5px;
    }
    .show1{
      margin-left: 390px !important;
      margin-top: 15px !important;
    
    
    }
    .inputWithIcon {
      position: relative;
    }
    
    .inputWithIcon i {
      position: absolute;
      left: 0;
      top: 8px;
      padding: 9px 8px;
      transition: 0.3s;
       /* padding-left: 30px;  */
       margin-left: 0px;
      margin-bottom: -6px;
      justify-content: center ;
    }
    .inputWithIcon.inputIconBg i {
      background-color: #aaa;
      color: #fff;
      padding: 9px 4px;
      border-radius: 4px 0 0 4px;
    }
    .inputWithIcon input[type="password"] ,input[type="email"]{
      padding-left: 10px;
    }
    .ulable{
      /* color: #C4C4C4; */
      color: rgb(153, 149, 149);
    
      text-align: left;
        margin-left: 42px;
        margin-top: 18px;
        font-size: 14px !important;
     
    }
    .sgnin{
      font-family: 'Open Sans Semibold', 'Open Sans Regular', 'Open Sans';
        font-weight: 600;
        font-style: normal;
        font-size: 14px !important;
        text-align: left;
        margin-top: -40px;
        color: #333333;
      }
      .redplog{
        color: red;
        text-align: left;
        margin-top: 4px;
        margin-left: 49px;
      }
      .place{
        width:396px;
      height: 47px;
        border: 1px solid rgb(230, 227, 227);
        border-radius: 3px;
         margin-top: 2px; 
      }
    .forgot{
      left: 0px;
        width: 114px;
        font-style: normal;
        /* color: rgb(153, 149, 149); */
           color: #C4C4C4 !important; 
      text-decoration: none !important;
        margin-left: 27px;
        padding-left: 5px;
        font-size: 14px !important;
    }
    .forgotpasstxt{
      color: #ffffff; 
    }
    .welcometext{
      font-size: 25px;
      margin-top: 43px;
      letter-spacing:0.5px;
    }
    loginlogo{
      margin-top: 20px;
    }
    .remember{
      /* margin-left: 39px; */
      margin-left: 41px;
      /* color: rgb(153, 149, 149); */
      color: #C4C4C4; 
      font-size: 18px !important;
    
    }
    .chek{
      /* color: rgb(153, 149, 149); */
      font-size: 12px;
      color: #C4C4C4; 
    
    
    
    
    
    }
    .sub{
      border-width: 0px;
        left: 0px;
        top: 0px;
        margin-left: 128px;
        width: 147px;
        height: 52px;
        background: inherit;
        background-color: #A47355;
        border: none;
        border-radius: 5px;
        box-shadow: none;
        color: #FFFFFF;
        margin-top: -6px;
        text-align: center;
        font-weight: bold;
        font-size: 14px !important;
        letter-spacing: 1px;
        border-color: #A47355 !important;
    
    }
    .sub:hover{
      cursor: pointer;
      background-color: #6B4C39;
      border-color: #A47355 !important;

    }
    .sign{
      margin-left: -124px;
    }
    .textfieldmui{
        width: 300px !important;
        border-color: #ffffff !important;
        color: #ffffff !important;
    }
    #outlined-adornment-password{
      color: #ffffff !important;
      background-color: transparent !important;
    }
    #outlined-adornment-password1{
      color: #333333 !important;
      background-color: transparent !important;
    }
    .logimg1{
      margin-top: 108px;
      margin-left: 48px;
    }
    .passlb{
      color: #ffffff !important;
      font-size: 14px !important;
    }
     
    /* .MuiInputBase-input{
      color: #FFFFFF !important;

    } */
    
    /* .MuiInputBase-root{
 border: 1px solid #FFFFFF;
    } */ 
    
    